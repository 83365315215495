
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import CalculationFunctionService from "../../services/sporteventCalculation/CalculationFunctionService";
import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import Discipline from "../../models/sporteventCalculation/Discipline";

@Component({})
export default class DisciplinesComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Discipline[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "calculationFunctions",
      sortable: false,
      class: "text-center",
      label: i18n.tc("sporteventCalculation.calculationFunctions"),
    },
    {
      key: "actions",
      sortable: false,
      class: "text-center",
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAll(): void {
    DisciplineService.getAll(true).then((items: Discipline[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
      }
      this.loading = false;
    });
  }

  mounted(): void {
    this.getAll();
  }

  // CSV Import
  public csvFormData = new FormData();
  // eslint-disable-next-line
  handleFileUpload(event: any) {
    // console.log(event);
    this.csvFormData.append(
      "disciplineCsv",
      event.target.files[0],
      event.target.files[0].name
    );
  }
  uploadCsv(): void {
    this.loading = true;
    DisciplineService.uploadCsv(this.csvFormData).then(() => {
      //(response) => {
      // console.log(response);
      this.getAll();
      this.loading = false;
    });
  }

  public csvCalcFormData = new FormData();
  // eslint-disable-next-line
  handleCalcFileUpload(event: any) {
    // console.log(event);
    this.csvCalcFormData.append(
      "calculationFunctionCsv",
      event.target.files[0],
      event.target.files[0].name
    );
  }
  uploadCalcCsv(): void {
    this.loading = true;
    CalculationFunctionService.uploadCsv(this.csvCalcFormData).then(() => {
      //(response) => {
      // console.log(response);
      this.getAll();
      this.loading = false;
    });
  }
}
